import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import djsData from '../assets/data/menuitems.json';
import '../assets/scss/project-details.scss';
// import TileScrollVariation1 from '../components/TileScrollVariation1.jsx';
// import TileScrollVariation2 from '../components/TileScrollVariation2.jsx';
// import TileScrollVariation3 from '../components/TileScrollVariation3.jsx';
import ScrollBasedLayoutAnimations from '../components/ScrollBasedLayoutAnimations.jsx';

const ProjectDetails = () => {
  const { slug } = useParams();
  const curentDjData = djsData.find((dj) => dj.slug === slug);

  useEffect(() => {
    
    // Scroll to the top of the page on component update
    window.scrollTo(0, 0);
  }, [slug]);
 
  if (!curentDjData) {
    return <div>Project not found</div>;
  }



  return (
    <div className="fade" key={slug}>
  
      {/* TileScroll ANIMATIONS PAS FIABLES = ABERRATION LOCOMOTIVE SCROLL */}
      {/* <TileScrollVariation1 djData={curentDjData}></TileScrollVariation1> */}
      {/* <TileScrollVariation2></TileScrollVariation2> */}
      {/* <TileScrollVariation3></TileScrollVariation3> */}

      {/* INTERFERENCE BETWEEN SEVERAL SCROLL EVENT LIBRARY  */}
      {/* <OnScrollShapeMorph djData={curentDjData}></OnScrollShapeMorph> */}

      <ScrollBasedLayoutAnimations djData={curentDjData}></ScrollBasedLayoutAnimations>
      {/* 
      <div>
        <h2>{project.name}</h2>
        <p>{project.aboutTheProject}</p>
        <img src={process.env.PUBLIC_URL + project.mainImage} alt="" />
      </div>
      <div>
        Prev <Link to={`/project/${project.prevProjectSlug}`}>{project.prevProjectName}</Link>
      </div>
      <div>
        <img src={process.env.PUBLIC_URL + project.prevProjectImage} alt="" />
      </div>

      <div>
        Next <Link to={`/project/${project.nextProjectSlug}`}>{project.nextProjectName}</Link>
      </div>
      <div>
        <img src={process.env.PUBLIC_URL + project.nextProjectImage} alt="" />
      </div> 
      */}
    </div>
  );
};

// Warning export default transition(TileScrollVariation1) ==> block props
export default ProjectDetails;
