import React, { useEffect } from 'react';
import transition from '../transition';
import '../assets/scss/ImageTilesMenu.scss';
import { animation } from '../assets/js/ImageTilesMenu/index.js'
import { useLocation } from 'react-router-dom';
// Import the JSON data
import dataMenuItems from '../assets/data/menuitems.json';
import { Link } from 'react-router-dom';
import LogoBehindTheDecks from '../components/Svg/LogoBehindTheDecks.jsx';

const Variation1 = () => {

  const location = useLocation();
  let pos = 1;

  // The useEffect hook is used to ensure that your code runs after the component has mounted and the initial render is complete.
  // The empty dependency array ([]) ensures that the effect runs exactly once after the initial rendering.
  useEffect(() => {
    // Call animation on route change
    animation();
    const delay = 100
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, delay);
    return () => clearTimeout(timer);
    
  }, [location]); // Dependency on location to trigger on route change

  return (
    <div className='ImageTilesMenu__container demo-1 loading'>
      <LogoBehindTheDecks />

      <main>
        <div className="thumbgrid-wrap">
          {dataMenuItems.map((row, rowIndex) => (
            // only be rendered if active is true.
            row.active && (
              <div key={rowIndex} className={`thumbgrid thumbgrid--${rowIndex + 1}`}>
                {row.imagesHoverMenu.map((imgSrc) => (
                  <div key={pos} className={`thumbgrid__item pos-${pos++}`}>
                   {/*  {pos - 1} */} <div className="thumbgrid__item-img" style={{ backgroundImage: `url(${imgSrc})` }}></div>
                  </div>
                ))}
              </div>
            )
          ))}
        </div>

        <nav className="menu">
          {dataMenuItems.map((item, index) => (
            // only be rendered if active is true.
            item.active && (<Link to={`/project/${item.slug}`} key={index} className="menu__item">
              <span className="menu__item-title">
                {/* Just for debugging for indication */} {/* {index + 1}. */}
                <i>{item.title.charAt(0)}</i>{item.title.slice(1)}
              </span>
              <span className="menu__item-desc">{item.description}</span>
            </Link>
            )
          ))}
        </nav>
      </main>
    </div>
  );
};

export default transition(Variation1);
