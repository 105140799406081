import React, { useRef, useState, useEffect } from 'react';

const LazyBackground = ({ src, children }) => {
  const ref = useRef();
  const [loaded, setLoaded] = useState(false);

  // useEffect hook to perform side effects in the component
  useEffect(() => {
    const currentRef = ref.current; // Capture the current value of ref.current
    // Creates a new IntersectionObserver instance. 
    // The observer takes a callback function that receives an array of entries, 
    // each representing an element being observed and its intersection status.
    const observer = new IntersectionObserver(
      ([entry]) => { // Destructuring the first entry from the entries array
        if (entry.isIntersecting) { // Checks if the observed element is intersecting with the viewport or root
          setLoaded(true); // Updates state to indicate the element is loaded (and can be displayed)
          observer.disconnect(); // Stops observing the current element to improve performance
        }
      },
      // { threshold: 0.01 } // Options for the observer. Here, it triggers the callback when at least 1% of the target is visible.
      { threshold: .05 } // Options for the observer. Here, it triggers the callback when at least 0% of the target is visible.

    );

    if (currentRef) { // Checks if the ref is attached to an element
      observer.observe(currentRef); // Starts observing the element referenced by `ref` for intersection changes
    }

    // Cleanup function to run when the component unmounts or before the effect runs again
    return () => {
      if (currentRef) { // Ensures the element is still mounted before trying to unobserve
        observer.disconnect(); // Stops observing the current element to clean up resources
      }
    };
  }, []); // Empty dependency array means this effect runs once after the initial render


  // Apply a transition effect for smooth loading
  const style = loaded ? { backgroundImage: `url(${src})` } : {};
  const transitionStyle = {
    transition: 'opacity 2s ease-out',
    opacity: loaded ? 1 : 0, // Start with 0 opacity and transition to 1
    backgroundSize: 'cover', // Ensure the background covers the element
    backgroundPosition: 'center', // Center the background image
    height: '100%', // Set a specific height or make it responsive to content
  };

  return (
    <div
      ref={ref}
      className="gallery__item-inner"
      style={{ ...style, ...transitionStyle }}
    >
      {children}
    </div>
  );
};

export default LazyBackground;