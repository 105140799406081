import {motion} from "framer-motion"

/*
    Examples of transition:

    initial={{ y: -20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{duration: 0.7,ease: [0.6, -0.05, 0.01, 0.99]}}

*/

// IMPORTANT FROM DOCUMENTATION

// https://www.framer.com/motion/animate-presence/##exit-animations

/*
    Note: Direct children must each have a unique key prop so AnimatePresence
    can track their presence in the tree.
*/
const transition = (OgComponent) => {
    return() => (
        <>
           <OgComponent/>
            {/* It works with an open eyes effect */}
             <motion.div
                className="slide-in"
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            />
            <motion.div
                key="div-out"
                className="slide-out"
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            />

            {/*  <motion.div
                className="slide-in"
                initial={{ translateX: '100vh', scaleY: 1}}
                animate={{ translateX: '0', scaleY: 0}}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            />
            <motion.div
                key="div-out"
                className="slide-out"
                initial={{ translateX: '100vh', scaleY: 1}}
                animate={{ translateX: '100vh', scaleY: 0}}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            /> */}
        </>
    )
}

export default transition;