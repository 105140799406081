import React from 'react';
import '../../assets/scss/logo.scss';

const LogoBehindTheDecks = () => {
    return (
        <div className='LogoBehindTheDecks-container'>
            <svg className="LogoBehindTheDecks-svg" viewBox="94.898 100.6831 259.5027 128.7742" xmlns="http://www.w3.org/2000/svg">
                <text style={{ fontFamily: 'Arial, sans-serif', fontSize: '16.7px', fontStyle: 'italic', fontWeight: '700', whiteSpace: 'pre' }} transform="matrix(2.6699299812316895, 0, 0, 2.7125120162963867, -78.83666229248047, -116.88603973388672)" x="65.952" y="95.258">BEHIND</text>
                <text style={{ fontFamily: 'Arial, sans-serif', fontSize: '16.7px', fontStyle: 'italic', fontWeight: '700', whiteSpace: 'pre' }} x="271.076" y="155.329">THE</text>
                <text style={{ fontFamily: 'Arial, sans-serif', fontSize: '16.7px', fontStyle: 'italic', fontWeight: '700', whiteSpace: 'pre' }} transform="matrix(3.202929973602295, 0, 0, 3.847188949584961, -381.3785095214844, -596.8547973632812)" x="171.111" y="211.252">DECKS</text>
                <rect x="94.898" y="148.897" width="171.901" height="2.115" style={{ stroke: 'none' }} />
            </svg>
        </div>
    );
};

export default LogoBehindTheDecks;
