import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../assets/scss/navigation.scss';
import LogoBehindTheKizomba from '../components/Svg/LogoBehindTheKizomba.jsx';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// function extractSlugFromPath(pathname) {
//   const pathElements = pathname.split('/');
//   return pathElements[pathElements.length - 1] || 'Home';
// }

function extractSlugFromPath(pathname) {
  const pathElements = pathname.split('/');
  return pathElements.length > 1 ? pathElements[pathElements.length - 1] : 'Home';
}

const Navigation = () => {
  const location = useLocation();
  // console.log('location =', location)

  const handleClick = (event) => {
    // Check if the condition is met (e.g., if the current pathname matches the link's destination)
    if (location.pathname === '/') {
      // Prevent the default link behavior
      event.preventDefault();
      // Additional logic can be added here if needed (e.g., displaying a message)
    }
  };

  useEffect(() => {
    // Access the pathname from the location object and set the document title
    const pageTitle = (capitalizeFirstLetter(extractSlugFromPath(location.pathname)) || 'Home').replace('-', ' ')
    document.title = `BEHIND THE KIZOMBA | ${pageTitle !== 'Home' ? `${pageTitle}` : 'Home'}`;
  }, [location.pathname]);

  return (
    <nav className='navigation__container'>
      <ul className='navbar'>
        <li>
          <NavLink to="/" className={"logo-navigation-link animate-border " + (location.pathname === '/' ? 'active' : '')} onClick={handleClick}>
            <LogoBehindTheKizomba />
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className={"about-navigation-link animate-border" + (location.pathname === '/about' ? 'active' : '')} >
            About
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
