import React from 'react';
import transition from '../transition';
import '../assets/scss/ScrollBasedLayoutAnimations.scss';
import '../assets/scss/about.scss';
import aboutData from '../assets/data/about.json';

const About = () => {
  // console.log(aboutData)
  return (
    <div className='ScrollBasedLayoutAnimations__container'>
      <h2 className="project__title">
        <span className="project__title-line about-title">About</span>
      </h2>
     {aboutData.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item.descriptions).map(([key, value], entryIndex) => (
            // Combine `index` and `entryIndex` to form a unique key
            <section className="project project--intro" key={`${index}-${entryIndex}`}>
              <span className="project__label project__label--mission">{key}</span>
              <div className="project__mission">
                <p>{value.part1}</p>
                <p>{value.part2}</p>
              </div>
            </section>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default transition(About);
