import React from 'react';
import '../../assets/scss/logo.scss';

const LogoBehindTheKizomba = () => {
  return (
    <svg className="LogoBehindTheKizomba-svg" width="200" height="80" viewBox="0 0 600 200" xmlns="http://www.w3.org/2000/svg">
      <text
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '16.7px',
          fontStyle: 'italic',
          fontWeight: '700',
          whiteSpace: 'pre'
        }}
        transform="matrix(2.66993, 0, 0, 2.712512, -78.836662, -116.88604)"
        x="65.952" y="95.258"
      >
        BEHIND
      </text>
      <text
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '16.7px',
          fontStyle: 'italic',
          fontWeight: '700',
          whiteSpace: 'pre'
        }}
        x="271.013" y="154.986"
      >
        THE
      </text>
      <text
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '16.7px',
          fontStyle: 'italic',
          fontWeight: '700',
          whiteSpace: 'pre'
        }}
        transform="matrix(3.20293, 0, 0, 3.847189, -381.37851, -596.854797)"
        x="171.111" y="211.252"
      >
        KIZOMBA
      </text>
      <rect
        x="94.898" y="148.897" width="171.901" height="2.115"
        style={{ stroke: 'rgb(0, 0, 0)' }}
      />
    </svg>

  );
};

export default LogoBehindTheKizomba;

